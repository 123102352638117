import React from "react";
import ContactUs from '../ContactUs/contactus';
import CustomerRating from '../CustomerRating/customerRating';
import SafeHTML from "../safehtml"
import "./generalcomponentcontainer.scss";
import SiteSearch from "../Search/sitesearch";
import Fade from 'react-reveal/Fade';
function SectionHeader(props) {
    return (
        <section className="comp-section-header" id={props.id}>
            {props.title && <div className="container">
                <h2 className="section-header-title">{props.title}</h2>
            </div>
            }
        </section>
    );
};
function Header(props) {

    return (
        <section className="comp-header">
            <div className="container">
                <h2 className="section-title has-top-line text-center">{props.title}</h2>
            </div>
        </section>
    );
};
function Quote(props) {

    return (
        <section className="comp-quote">
            <div className="container">
                <blockquote className="comp-quote-inner">
                    <div className="container-fluid">
                        {props.title && <p className="h2 title">{props.title}</p>}
                        {props.description && <footer className="blockquote-footer"><SafeHTML>{props.description}</SafeHTML></footer>}
                    </div>
                </blockquote>
            </div>
        </section>
    );
};
export default function GeneralComponentContainer(props) {
    const GeneralComponents = {
        "Contact": <Fade ssrFadeout><ContactUs {...props}></ContactUs></Fade>,
        "Customer Rating": <Fade ssrFadeout><CustomerRating {...props}></CustomerRating></Fade>,
        "Quote": <Fade ssrFadeout><Quote {...props}></Quote></Fade>,
        "Section Header": <SectionHeader {...props}></SectionHeader>,
        "Header": <Fade ssrFadeout><Header {...props}></Header></Fade>,
        "Search": <Fade ssrFadeout><SiteSearch {...props}></SiteSearch></Fade>
    }
    return (
        GeneralComponents[props.componentType] ?  GeneralComponents[props.componentType] : <></>
    );
};
